<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="dataId?'编辑角色':'添加角色'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="500px" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" :autoCheck='true' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="tagsArrs" slot-scope="scope">
						<div>
							<TagBind :tagValueList="htmlDatas[scope.current.keys]" tagType :notAuto='true' :limit='10' :maxlength='6' addName='添加标签'/>
						</div>
					</template>
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isWait'> 保存</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import TForm from '@/components/YTable/TForm.vue';
	import TagBind from "@/components/TagBind";
	export default {
		props: {
			dataId: {
				type: String,
				default: '',
			},
			useType: {//使用类型， 选择 select 用户 或者手输 input 信息
				type: String,
				default: 'input',
			},
			enterpriseId: { //useType==select 时候 需要传企业ID enterpriseId
				type: String,
				default: '',
			},
		},
		components: {TForm,TagBind},
		data() {
			return {
				isWait:false,
				htmlDatas:{},
	  			formlist:[
	  				{name: '类型名称',keys: 'typeName',value: '',type: 'input',flex:24,isMust:true},
	  				{name: '类型说明',keys: 'typeExplain',value: '',type: 'input',flex:24},
	  				{name: '操作权限',keys: 'declarationAuthority',value: ['报单'],type: 'check',flex:24,isMust:true,options:[
	  					{name:'报单',code:'报单'},
//	  					{name:'复选框',code:'复选框'},
	  				]},
	  			],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$refs.htmlFrom.resetFields();
					this.getDatasById();
				}
			},
			
		},
		created() {
			this.resetTFrom();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			resetTFrom(){
				if(this.$refs.htmlFrom) this.$refs.htmlFrom.resetFields();
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			closeMypup(){
				this.$emit('close')
			},
			async getDatasById(){
				this.resetTFrom();	
				if(this.dataId){
					let res = await this.ApiHttp('/organization/authorityConfiguration/select/'+this.dataId);
					if(res){
						if(res.declarationAuthority){
							res.declarationAuthority=res.declarationAuthority.split(',')
						}else{
							res.declarationAuthority=[]
						}
						this.htmlDatas={...this.htmlDatas,...res}
					}
				}
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
				})
			},
			async getFromDatas(){
				let params={
					enterpriseId:+this.enterpriseId,
					...this.htmlDatas,
				}
				this.isWait=true;
				setTimeout(()=> { 
					this.isWait=false
				},1500);
				let urlType='insert'
				params.declarationAuthority=params.declarationAuthority.join(',');
				if(this.dataId){
					urlType='update';//编辑
				}else{
				}
				let res = await this.ApiHttp('/organization/authorityConfiguration/'+urlType,params,'post');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
				
				
			},
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>
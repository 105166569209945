<template>
  <div class="qy_detail app-container">
    <div class="tformWarp">
      <div class="topName">
        <span class="name">{{ htmlDatas.enterpriseName }}</span>
        <div v-if="htmlDatas.totalEntScore" style="display: inline">
          <span class="sc_num">{{ htmlDatas.totalEntScore }}</span>
          <span class="sc_text">分</span>
        </div>
      </div>
      <div class="lbsWarp">
        <span class="itlb" v-for="(it, dex) in htmlDatas.tagList" :key="dex">{{
          it
        }}</span>
      </div>
      <div class="txForm">
        <TForm
          ref="htmlFrom"
          :model="htmlDatas"
          :formlist="formlist"
          label-width="126px"
          labelPosition="left"
        >
          <template slot="score" slot-scope="scope">
            <div style="display: inline-block">
              <el-rate
                v-model="htmlDatas[scope.current.keys]"
                disabled
                show-score
                text-color="#ff9900"
              >
              </el-rate>
            </div>
          </template>
        </TForm>
      </div>
    </div>

    <div class="tyleTables">
      <el-button
        v-if="activeTab == 1"
        class="tabBtn"
        type="primary"
        size="small"
        plain
        @click="showCheckUser = true"
        >添加</el-button
      >
      <el-button
        v-if="activeTab == 4"
        class="tabBtn"
        type="primary"
        size="small"
        plain
        @click="showAddRole('add')"
        >添加角色</el-button
      >
      <el-button
        v-if="activeTab == 5"
        class="tabBtn"
        type="primary"
        size="small"
        plain
        @click="upFiles.isOpen = true"
        >上传文档</el-button
      >
      <el-tabs v-model="activeTab" @tab-click="tabsClick">
        <el-tab-pane
          v-for="(it, dex) in tabsArrs"
          :key="it.val"
          :disabled="tbloading"
          :label="it.num ? it.name + '(' + it.num + ')' : it.name"
          :name="it.val"
        >
          <div class="table-body">
            <TSeach
              v-if="activeTab == 5"
              :topSearch="topSearch"
              v-model="seachData"
            >
              <template slot="btnSlot">
                <div class="eventBtn rightBtnGroup">
                  <el-button
                    class="itBtn"
                    type="primary"
                    icon="el-icon-search"
                    :loading="tbloading"
                    @click="seach"
                    >{{ $t("commons.search") }}</el-button
                  ><!-- 查询 -->
                  <el-button class="itBtn" @click="batchDownload"
                    >批量下载</el-button
                  ><!-- 查询 -->
                </div>
              </template>
            </TSeach>
            <TSeach
              v-if="activeTab == 6"
              :topSearch="billSearch"
              v-model="seachData"
            >
              <template slot="btnSlot">
                <div class="eventBtn rightBtnGroup">
                  <el-button
                    class="itBtn"
                    type="primary"
                    icon="el-icon-search"
                    :loading="tbloading"
                    @click="seach"
                    >{{ $t("commons.search") }}</el-button
                  ><!-- 查询 -->
                </div>
              </template>
            </TSeach>
            <TTable
              :ref="'myTable' + it.val"
              :checkBox="activeTab == 5"
              :tbloading="tbloading"
              :tableData="tableData"
              serialNumber
              :tableTitle="it.tableTitle"
              :tbParams="tableParams"
              :maxHeight="440"
            >
              <template slot="operating" slot-scope="scope">
                <el-button type="text" @click.stop="tbEditStaff(scope.row)"
                  >编辑</el-button
                >
                <el-button type="text" @click.stop="tbUnbinding(scope.row)"
                  >解绑</el-button
                >
              </template>
              <template slot="roleOperating" slot-scope="scope">
                <el-button type="text" @click="showAddRole('edit', scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="delAddRole(scope.row)"
                  :disabled="scope.row.default"
                  >删除</el-button
                >
              </template>

              <template slot="alarmId" slot-scope="scope">
                <el-button type="text" @click.stop="toAlarmDeatil(scope.row)">{{
                  scope.row.alarmUUID
                }}</el-button>
              </template>

              <template slot="inspectionJobCode" slot-scope="scope">
                <el-button
                  type="text"
                  @click.stop="toInspectionDetail(scope.row)"
                  >{{ scope.row.inspectionJobCode }}</el-button
                >
              </template>

              <template slot="fileSlot" slot-scope="scope">
                <el-button type="text" @click="downloadFiles(scope.row)"
                  >文档下载</el-button
                >
                <el-button type="text" @click="showFileClass(scope.row)"
                  >编辑分类</el-button
                >
                <el-button type="text" @click="delRowFile(scope.row)"
                  >删除</el-button
                >
              </template>
              <template slot="orderNumber" slot-scope="scope">
                <el-button type="text" @click.stop="toOrderDetail(scope.row)">{{
                  scope.row.orderCode
                }}</el-button>
              </template>
              <template slot="overdue" slot-scope="scope">
                <span
                  :style="
                    scope.row.status == 2
                      ? { color: '#E8331C' }
                      : { color: '#303133' }
                  "
                  >{{ scope.row.statusText }}</span
                >
              </template>
            </TTable>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <TPagination v-model="tableParams" @initTable="initTable"></TPagination
    ><!-- 分页 -->
    <!--查看历史数据抽屉-->
    <el-dialog width="1100px" top="50px" :visible.sync="openHistorys">
      <lookHistorys
        v-if="openHistorys"
        :productKey="htmlDatas.productKey"
        :deviceName="datas.rlinkDeviceName"
        :moduleName="historyObj.moduleName"
        :propertyName="historyObj.propertyName"
        :description="historyObj.description"
      />
    </el-dialog>

    <!--tabs=企业员工 时候 选择用户-->
    <checkUsers
      v-if="showCheckUser"
      useType="select"
      :isOpen="showCheckUser"
      @close="closeCheckUser"
      :enterpriseId="htmlDatas.enterpriseId"
    />
    <!--tabs=企业员工 时候 编辑用户   选择跟编辑的时候 不一样 所以需要俩个不同逻辑的弹框-->
    <checkUsers
      :isOpen="editStaffs.open"
      @close="closeUserDialog"
      :dataId="String(editStaffs.editRowId)"
      :byEnterpriseEdit="true"
    />
    <!--tabs=企业角色配置 新增/编辑用户-->
    <addRole
      :isOpen="addRoleInfo.isOpen"
      @close="closeAddRoles"
      :enterpriseId="htmlDatas.enterpriseId"
      :dataId="addRoleInfo.dataId"
    />
    <editFileType
      :isOpen="fileClass.isOpen"
      @close="closeFileClass"
      :enterpriseId="htmlDatas.enterpriseId"
      :dataId="fileClass.dataId"
      :typeArrs="upFiles.typeArrs"
    ></editFileType>
    <!--导入文件-->
    <el-dialog :visible.sync="upFiles.isOpen" width="500px">
      <template slot="title">
        <title-icon />{{ $t("commons.uploadFile") }}
      </template>
      <div>
        <span style="color: red">*</span>选择文件类型：
        <el-select
          v-model="upFiles.documentType"
          filterable
          clearable
          placeholder="请选择"
        >
          <!--<el-option label="请选择" value=""></el-option> -->
          <el-option
            v-for="(item, dex2) in upFiles.typeArrs"
            :key="dex2"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </div>
      <div slot="footer">
        <el-upload
          ref="Yupload"
          :action="upFiles.rootPath"
          :file-list="upFiles.fileList"
          :on-success="handlerSuccess"
          :on-change="fileChange"
          :on-error="uploadFail"
          :with-credentials="true"
          :auto-upload="false"
        >
          <el-button slot="trigger" size="small" type="primary">{{
            $t("commons.chooseFile")
          }}</el-button>
          <el-button
            size="small"
            type="success"
            @click="submitFiles"
            style="margin-left: 10px"
            >{{ $t("commons.uploadToBg") }}</el-button
          >
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/mixins/TTable/pagination";
import seachAndTable from "@/mixins/TTable/seachAndTable";
import TSeach from "@/components/YTable/TSeach.vue";
import TTable from "@/components/YTable/TTable.vue";
import TForm from "@/components/YTable/TForm.vue";
import TPagination from "@/components/YTable/TPagination.vue";
import Pagination from "@/components/Pagination";
import handsRlink from "@/mixins/handsRlink.js";
import lookHistorys from "@/views/business/base/device/tenant/lookHistorys.vue";
import checkUsers from "@/views/business/enterprise/userInfos/model/addOrEdit.vue";
import addRole from "@/views/business/enterprise/listQuery/model/addRole.vue";
import editFileType from "@/views/business/enterprise/listQuery/model/editFileType.vue";
import {
  getCompanyList,
  getProjectList,
} from "@/api/business/order/tenant/order";
import { envInfo } from "@/constants/envInfo";
import Template from "@/views/ruge/modulx/template.vue";

export default {
  mixins: [pagination, seachAndTable, handsRlink],
  components: {
    TTable,
    TSeach,
    Pagination,
    TPagination,
    TForm,
    lookHistorys,
    checkUsers,
    addRole,
    editFileType,
    Template,
  },
  props: {
    datas: {
      type: Object,
      default: () => {
        return {
          rlinkDeviceName: "",
        };
      },
    },
    colNum: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      htmlDatas: {
        tagList: [],
      },
      upFiles: {
        typeArrs: [],
        isOpen: false,
        fileList: [],
        documentType: "",
        rootPath:
          "/saascloud/saas/archive/param/archive/upload?ulType=DefaultUpload",
      },
      addRoleInfo: {
        isOpen: false, //角色配置时 新增或编辑角色
        dataId: "",
      },
      editStaffs: {
        //编辑企业员工的弹框信息
        open: false,
        editRowId: "",
      },
      showCheckUser: false,
      deviceStatuStr: "",
      deviceClass: "", //设备状态颜色的类
      urlArrs: [
        { name: "企业员工", method: this.initUserTable },
        { name: "工单记录", method: this.initOrders },
        { name: "企业设备", method: this.initDeviceTable },
        { name: "角色配置", method: this.initRoleConfig },
        { name: "资料档案", method: this.initFileslist },
        { name: "账单管理", method: this.initBilllist },
      ],
      tabsInit: 0,
      formlist: [
        {
          name: "法人代表",
          keys: "operName",
          value: "",
          type: "text",
          flex: 6,
        },
        {
          name: "负责人电话",
          keys: "leaderPhone",
          value: "",
          type: "text",
          flex: 6,
        },
        {
          name: "统一社会信用代码",
          keys: "creditNo",
          value: "",
          type: "text",
          flex: 6,
        },
        {
          name: "所属行业",
          keys: "industryName",
          value: "",
          type: "text",
          flex: 6,
        },
        {
          name: "注册资金(万元)",
          keys: "registCapi",
          value: "",
          type: "text",
          flex: 6,
        },
        {
          name: "注册时间",
          keys: "termStart",
          value: "",
          type: "text",
          flex: 6,
        },
        {
          name: "地址",
          keys: "positionName",
          value: "",
          type: "text",
          flex: 6,
        },
      ],
      activeTab: "-1",
      isSmart: true, //是否智能设备

      tabsArrs: [
        {
          name: "企业员工",
          num: 0,
          val: "1",
          tableTitle: [
            { name: "用户名称", prop: "userName" },
            { name: "联系方式", prop: "phone" },
            { name: "性别", prop: "sexStr" },
            { name: "员工角色", prop: "employeeRoleName" },
            { name: "注册来源", prop: "source" },
            { name: "微信号 ", prop: "wechatNumber" },
            { name: "微信昵称", prop: "wechatName" },
            { name: "添加时间", prop: "addDateStr" },
            { name: "操作", slotName: "operating" },
          ],
        },
        {
          name: "工单记录",
          num: 0,
          val: "2",
          tableTitle: [
            { name: "工单编号", slotName: "orderNumber" },
            { name: "问题描述", prop: "problemDesc" },
            { name: "工单模版", prop: "configName" },
            { name: "责任部门", prop: "resDeptName" },
            { name: "维修人员", prop: "repairPeople" },
            { name: "报单部门", prop: "submitUserDeptName" },
            { name: "报单人员", prop: "submitUserName" },
            { name: "报单来源", prop: "orderSource" },
            { name: "工单状态", prop: "orderStatusStr" },
            { name: "报单时间", prop: "creationDateStr" },
          ],
        },

        {
          name: "企业设备",
          num: 4,
          val: "3",
          tableTitle: [
            { name: "项目名称", prop: "projectName" },
            { name: "设备名称", prop: "deviceName" },
            { name: "设备编码", prop: "factoryCode" },
            { name: "产品标识码", prop: "productKey" },
            { name: "设备标识码", prop: "rlinkDeviceName" },
            { name: "安装位置", prop: "descName" },
            { name: "关联企业", prop: "enterpriseName" },
            { name: "设备类型", prop: "deviceTypeName" },
          ],
        },
        {
          name: "角色配置",
          num: 2,
          val: "4",
          tableTitle: [
            { name: "角色类型", prop: "typeName" },
            { name: "类型说明", prop: "typeExplain" },
            { name: "报单权限", prop: "declarationAuthority" },
            { name: "操作", slotName: "roleOperating", width: "180" },
          ],
        },
        {
          name: "资料档案",
          num: 0,
          val: "5",
          tableTitle: [
            { name: "文档名称", prop: "documentName" },
            { name: "文档类型", prop: "documentTypeName" },
            { name: "文档格式", prop: "documentFormat" },
            { name: "创建时间", prop: "creationDateStr" },
            { name: "创建人", prop: "createdName" },
            { name: "操作", slotName: "fileSlot", width: "240" },
          ],
        },
        {
          name: "账单管理",
          num: 0,
          val: "6",
          tableTitle: [
            { name: "账单类型", prop: "billStatus" },
            { name: "应收款日", prop: "receivableDateStr" },
            { name: "收款日期", prop: "receivedDateStr" },
            { name: "收费项应收", prop: "totalcost" },
            { name: "收费项已收", prop: "transactionAmount" },
            { name: "收费项待收", prop: "toBeReceived" },
            { name: "状态", slotName: "overdue", width: "240" },
          ],
        },
      ],
      openHistorys: false,
      historyObj: {
        //设备参数列表  查看历史记录所需数据
        isOpen: false,
        productKey: "",
        deviceName: "",
        moduleName: "",
        propertyName: "",
        description: "",
        currentDataType: "",
      },
      companyList: [], //公司列表
      projectList: [], //项目列表
      fileClass: {
        isOpen: false, //资料档案编辑分类
        dataId: "",
      },
      peopleOptions: [],
    };
  },
  created() {
    this.initHtmlDatas();
    this.initFileTypeOptions();
    const seachArr = [
      {
        name: "文档名称",
        type: "text",
        bindKeys: "documentName",
        defaultValue: "",
      },
      {
        name: "文档类型",
        type: "select",
        bindKeys: "documentType",
        defaultValue: "",
        option: [],
      },
      {
        name: "创建人",
        type: "select",
        bindKeys: "createdBy",
        defaultValue: "",
        option: [],
      },
    ];
    this.topSearch = seachArr; //参数需注入到minxs

    const seachScr = [
      {
        name: "账单选择",
        type: "select",
        bindKeys: "classify",
        defaultValue: "",
        option: [
          {
            label: "租赁账单",
            value: 1,
          },
          {
            label: "物业账单",
            value: 2,
          },
        ],
      },
      {
        name: "时间筛选",
        type: "month",
        bindKeys: "monthKey",
        defaultValue: "",
        valueFormat: "yyyy-MM",
        option: [],
      },
      {
        name: "状态筛选",
        type: "select",
        bindKeys: "status",
        defaultValue: "",
        option: [
          {
            label: "待收款",
            value: 1,
          },
          {
            label: "已逾期",
            value: 2,
          },
          {
            label: "已收款",
            value: 3,
          },
          {
            label: "已结清",
            value: 4,
          },
          {
            label: "已付款",
            value: 6,
          },
          {
            label: "待付款",
            value: 7,
          },
        ],
      },
    ];
    this.billSearch = seachScr;

    this.initCompanyList();
    this.initPeopleList();
  },
  methods: {
    async initBilllist(params) {
      //账单
      let uStartDate;
      let uEndDate;
      if (params.monthKey) {
        var year = params.monthKey.slice(0, 4);
        var month = params.monthKey.slice(5, 7);
        var firstDay = new Date(year, month, 1); // 第一天
        var lastDay = new Date(
          firstDay.getTime() - 1000 * 60 * 60 * 24
        ).getDate(); // 获取当月最后一天日期
        const uStart =
          year + "-" + month + "-" + firstDay.getDate() + " 00:00:00";
        const uEnd = year + "-" + month + "-" + lastDay + " 23:59:59";
        uStartDate = new Date(uStart).valueOf() / 1000;
        uEndDate = new Date(uEnd).valueOf() / 1000;
      }
      const postData = {
        tenantName: this.htmlDatas.enterpriseName,
        classify: params.classify ? params.classify : 1,
        startTime: uStartDate ? uStartDate : null,
        endTime: uEndDate ? uEndDate : null,
        status: params.status ? params.status : 0,
        page: params.current,
        limit: params.rowCount,
      };
      let backData;
      let res = await this.ApiHttp(
        "/organization/qixin/companySumXcx",
        postData,
        "post"
      );
      if (res) {
        let stage;
        let startDate;
        let endDate;
        let statusText;
        let statusTextList = [
          "待收款",
          "已逾期",
          "已收款",
          "已结清",
          "",
          "已付款",
          "待付款",
        ];
        res.data.list.forEach((item) => {
          item.receivableDateStr = item.receivableDateStr.slice(0, 10);
          item.receivedDateStr = item.receivedDateStr.slice(0, 10);
          stage = item.periodNumber === 0 ? "押金" : `第${item.periodNumber}期`;
          startDate = item.rentalBeginDateStr.slice(0, 10);
          endDate = item.rentalEndDateStr.slice(0, 10);
          item.totalcost = this.thousandBit(item.totalcost);
          item.transactionAmount = this.thousandBit(item.transactionAmount);
          item.toBeReceived = this.thousandBit(item.toBeReceived);
          statusText =
            item.status == 2
              ? (statusText = `逾期${item.overdueDays}天`)
              : (statusText = statusTextList[item.status - 1]);

          this.$set(item, "statusText", statusText);
          this.$set(item, "billStatus", stage + "" + startDate + "-" + endDate);
        });
        backData = { ...res.data };
      }
      return backData;
    },
    thousandBit(value) {
      if (!value) return "￥0.00";
      // 将值转换为字符串，并将所有非数字字符替换为空字符串
      value = parseFloat(value).toFixed(2);

      // 将数值分成整数部分和小数部分
      var parts = value.split(".");
      var integers = parts[0];
      var decimals = parts[1];

      // 对整数部分进行千分位格式化
      integers = integers.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,");

      // 将整数和小数部分重新组合
      return "￥" + integers + "." + decimals;
    },
    showAddRole(type, row) {
      let id = "";
      if (type == "edit") id = row.id;
      this.addRoleInfo = {
        isOpen: true,
        dataId: id,
      };
    },

    delRowFile(row) {
      //删除角色配置添加的角色
      this.$confirm("是否确认删除", "提示")
        .then(() => {
          this.ApiHttp(
            "/organization/enterprise/document/delete?enterpriseDocumentId=" +
              row.enterpriseDocumentId,
            {},
            "DELETE"
          ).then((res) => {
            if (res) {
              this.initTable();
              this.$message({
                type: "success",
                message: "操作成功",
              });
            }
          });
        })
        .catch(() => {});
    },

    delAddRole(row) {
      //删除角色配置添加的角色
      this.$confirm("是否确认删除", "提示")
        .then(() => {
          this.ApiHttp(
            "/organization/authorityConfiguration/delete/" + row.id,
            {},
            "DELETE"
          ).then((res) => {
            if (res) {
              this.initTable();
              this.$message({
                type: "success",
                message: "操作成功",
              });
            }
          });
        })
        .catch(() => {});
    },
    tbEditStaff(row) {
      //编辑企业员工
      this.editStaffs = {
        open: true,
        editRowId: row.userId + "",
      };
    },
    tbUnbinding(row) {
      //解绑

      this.$confirm(
        "您即将解除该用户与该企业的关系，但不影响该用户已生成的数据，是否确认解绑？",
        "提示"
      )
        .then(() => {
          let params = {
            ...row,
            enterpriseId: 0,
          };
          this.ApiHttp("/organization/userInfo/update", params, "post").then(
            (res) => {
              if (res) {
                this.initTable();
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
              }
            }
          );
        })
        .catch(() => {});
    },

    async initHtmlDatas() {
      this.activeTab = this.tabsArrs[0].val;
      this.resetTFrom();
      this.getDetailById();
      this.htmlDatas.enterpriseId = this.$route.query.enterpriseId;
      await this.initTable();
      this.tabsArrs.map((it) => {
        this.tabsInit++;
        this.initTable(it.val);
      });
    },
    resetTFrom() {
      for (let item of this.formlist) {
        //初始化赋值我的表单数据
        this.$set(this.htmlDatas, item.keys, item.value);
      }
    },
    async getDetailById() {
      const { enterpriseId } = this.$route.query;
      if (!enterpriseId) return;
      let res = await this.ApiHttp(
        "/organization/enterprise/select/" + enterpriseId
      );
      if (res) {
        res.tagList = [];
        if (res.enterpriseTag) res.tagList = res.enterpriseTag.split(",");
        if (res.enterpriseId) res.enterpriseId = res.enterpriseId + "";
        this.htmlDatas = { ...this.htmlDatas, ...res };
        console.log("this.htmlDatas", this.htmlDatas);
      }
    },

    toAlarmDeatil(row) {
      const routeData = this.$router.resolve({
        path: "/iot/alarmRecords/detail",
        query: {
          rowId: row.alarmUUID,
        },
      });
      window.open(routeData.href, "_blank");
      //	  			this.$router.push('/?rowId=9a25795cff694e66a991108effdce670')
    },
    toOrderDetail(row) {
      let params = {
        path: "/iot/orderQuery/detail",
        query: {
          orderId: row.orderId,
        },
      };
      this.$router.push(params);

      //	  			const routeData = this.$router.resolve({
      //	              	path: '/iot/orderQuery/detail',
      //					query: {
      //						orderId: row.orderId,
      //					}
      //	            })
      //	            window.open(routeData.href, '_blank')
    },
    toInspectionDetail(row) {
      const routeData = this.$router.resolve({
        path: "/tenant/inspection/inspectionSearch/list",
        query: {
          inspectionJobId: row.inspectionJobId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    tbRowHistory(row) {
      //查看历史记录
      this.historyObj = { ...row };
      this.openHistorys = true;
    },
    tabsClick(val) {
      if (this.tbloading) {
        this.$message("数据加载中");
        return;
      }
      this.tableParams.current = 1;
      this.initTable();
    },
    closeUserDialog(init) {
      //关闭弹框
      this.editStaffs = {
        open: false,
        editRowId: "",
      };
      if (init) this.initTable();
    },
    closeCheckUser(init) {
      this.showCheckUser = false;
      if (init) this.initTable();
    },
    async initDeviceTable(params) {
      let backData;
      let res = await this.ApiHttp("/base/tenant/device/page", params);
      if (res) {
        backData = { ...res };
      }
      return backData;
    },
    async initUserTable(params) {
      let backData;
      if (params) params.orderByAddDate = "1";
      let res = await this.ApiHttp("/organization/userInfo/page", params);
      if (res) {
        res.rows.map((it) => {
          it.sexStr = it.sex == "1" ? "男" : "女";
          it.addDateStr = this.common.getTimeMmss(it.addDate);
        });

        backData = { ...res };
      }
      return backData;
    },
    async initOrders(params) {
      let backData;
      let res = await this.ApiHttp(
        "/order/tenant/orderFill/conditionalQuery",
        params
      );
      if (res) {
        res.rows.map((it) => {
          it.repairPeople = it.handlePersonName; //维修人员
          if (it.orderStatus == 1) it.repairPeople = "待领取";
          if (it.orderStatus == 6) it.repairPeople = "/";
          it.orderStatusStr = ""; //工单状态
          if (it.orderStatus)
            it.orderStatusStr = this.OrderStatusObj[it.orderStatus];
        });

        backData = { ...res };
      }
      return backData;
    },
    async initFileslist(params) {
      //资料档案列表
      let backData;
      let res = await this.ApiHttp(
        "/organization/enterprise/document/page",
        params
      );
      if (res) {
        backData = { ...res };
      }
      return backData;
    },
    showFileClass(row) {
      //编辑文件分类
      let id = row.enterpriseDocumentId;
      this.fileClass = {
        isOpen: true,
        dataId: id,
      };
    },
    closeFileClass(init) {
      this.fileClass = {
        isOpen: false, //编辑资料 档案文件分类
        dataId: "",
      };
      if (init) {
        this.initTable();
      }
    },
    async initRoleConfig(params) {
      //角色配置列表
      let backData;
      let res = await this.ApiHttp(
        "/organization/authorityConfiguration/page",
        params
      );
      if (res) {
        backData = { ...res };
      }
      return backData;
    },
    batchDownload() {
      let rowArr = this.$refs.myTable5[0].selection;
      if (rowArr.length < 1) {
        this.$message("请选择");
        return;
      }
      rowArr.map((row) => {
        this.downloadFiles(row);
      });
    },
    async initPeopleList() {
      //获取人员数据
      let res = await this.ApiHttp("/organization/tenant/employee/empList", {
        isloading: false,
      });
      if (res) {
        res.map((it) => {
          it.label = it.userName;
          it.value = String(it.userId);
        });
        this.common.injectionOption("createdBy", res, this.topSearch);
        this.peopleOptions = res;
      }
    },
    downloadFiles(row) {
      console.log("4545", row.fileId);
      let fileUrl =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        row.fileId;
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = row.documentName;
      a.click();
    },
    async initFileTypeOptions() {
      //获取文件类型服务类型
      let res = await this.ApiHttp("/lookup/lookup/item/find", {
        isloading: false,
        classifyCode: "ENTE_DOC_TYPE",
      });
      console.log("initFileTypeOptions", res);
      if (res) {
        res.lookupItemList.map((it) => {
          it.name = it.itemName;
          it.code = it.itemCode;
          it.label = it.itemName;
          it.value = it.itemCode;
        });
        this.upFiles.typeArrs = res.lookupItemList; //文档类型数据
        this.common.injectionOption(
          "documentType",
          this.upFiles.typeArrs,
          this.topSearch
        );
      }
    },

    closeAddRoles(init) {
      this.addRoleInfo = {
        isOpen: false, //角色配置时 新增或编辑角色
        dataId: "",
      };
      if (init) {
        this.initTable();
      }
    },
    async submitFiles() {
      let params = {
        enterpriseId: this.datas.enterpriseId,
        documentType: this.upFiles.documentType,
        files: [],
      };
      for (let item of this.upFiles.fileList) {
        let res = await this.httpFiles(item.raw);
        console.log("res", params);
        if (res) {
          params.files.push(res);
        }
      }
      if (!params.documentType || params.files.length < 1) {
        this.$message("上传文件或类型不能为空");
        return;
      }
      console.log("45454params", params);
      let bkinfo = await this.ApiHttp(
        "/organization/enterprise/document/insert",
        params,
        "post"
      );
      if (bkinfo) {
        console.log("bkinfo", bkinfo);
        this.initTable();
        this.handlerSuccess();
        this.$message({ type: "success", message: "操作成功" });
      }
      //	      this.$refs.Yupload.submit();
    },
    handlerSuccess(response) {
      this.upFiles.isOpen = false;
      this.upFiles.fileList = [];
    },
    async fileChange(myFile, fileArrs) {
      this.upFiles.fileList = fileArrs;
      console.log("fileChange", fileArrs);
    },
    initCompanyList() {
      getCompanyList().then((res) => {
        this.companyList = res;
        res.map((it) => {
          it.label = it.companyName;
          it.value = it.companyId;
        });
        this.common.injectionOption(
          "companyId",
          this.companyList,
          this.topSearch
        );
      });
    },
    //切换公司
    changeCompany(companyId) {
      this.seachData.projectId = "";
      if (companyId) {
        getProjectList({
          companyId: companyId,
        }).then((res) => {
          res.map((it) => {
            it.label = it.projectName;
            it.value = it.projectId;
          });
          this.projectList = res;
          this.common.injectionOption(
            "projectId",
            this.projectList,
            this.topSearch
          );
        });
      }
    },
    async httpFiles(myFile) {
      let _result;
      if (myFile) {
        console.log("httpFiles");
        if (myFile.size > 20 * 1024 * 1024) {
          this.$message("文件大小不能超过20M");
          return;
        }
        let formData = new FormData();
        formData.append("file", myFile);
        let backRes = await this.ApiHttp(
          "/archive/param/archive/upload?ulType=DefaultUpload",
          formData,
          "post",
          true
        );
        setTimeout(() => {
          this.uploading = false;
        }, 500);
        if (backRes && backRes.length > 0) {
          _result = {
            documentName: myFile.name, //名称
            fileId: backRes[0].fileId, //id
            documentFormat: backRes[0].contentType, //格式
          };
        }
      }
      return _result;
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    async initTable(tbNum) {
      //tbNum 当指定类型的时候  去获取各类型的数量  但是不赋值
      let tbVal = tbNum ? Number(tbNum) : Number(this.activeTab);
      if (!tbNum) {
        this.tableData = [];
      }
      this.tbloading = true;
      let index = tbVal - 1;
      let reqMethod = this.urlArrs[index].method;
      let params = {
        ...this.tableParams,
        enterpriseId: this.$route.query.enterpriseId,
      };
      if (tbVal == 5 || tbVal == 6) {
        params = {
          ...this.seachData,
          ...params,
        };
      }
      delete params.total;
      let res = await reqMethod(params);
      if (!tbNum) {
        if (res.rows) {
          this.tableData = res.rows;
        } else {
          this.tableData = res.list;
        }
        this.tableParams.total = res.total;
      } else {
        if (tbVal == "1" && res.deviceStatus) {
          this.deviceStatuStr = this.DeviceStatusObj[res.deviceStatus];
          this.deviceClass = res.deviceStatus;
        }
      }

      this.tabsArrs.map((it) => {
        if (it.val == tbVal) {
          it.num = res.total;
        }
      });
      if (tbNum) {
        if (this.tabsInit >= this.tabsArrs.length) {
          this.$nextTick(() => {
            this.tbloading = false;
          });
          //						setTimeout(()=>{
          //							this.tbloading=false;
          //						},500)
          return;
        }
      } else {
        this.tbloading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.qy_detail {
  .tformWarp {
    border: 1px solid #dcdfe6;
    padding: 20px;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
  .topName {
    .name {
      font-size: 16px;
      font-weight: bold;
      color: #303133;
      line-height: 26px;
    }
    .lbs {
      margin: 0 6px;
      padding: 2px 5px;
      font-size: 12px;
      color: #3880ff;
      border-radius: 2px;
      border: 1px solid #3880ff;
      position: relative;
      top: -2px;
    }
    .lbs.ONLINE {
      color: #67c23a;
      border: 1px solid #67c23a;
    }
    .lbs.OFFLINE {
      color: #f56c6c;
      border: 1px solid #f56c6c;
    }
    .sc_num {
      margin-left: 12px;
      font-weight: 500;
      font-size: 18px;
      color: #f3af00;
    }
    .sc_text {
      font-weight: 500;
      font-size: 12px;
      color: #f3af00;
    }
  }
  .lbsWarp {
    margin: 10px 0;
    .itlb {
      background: #ecf5ff;
      border-radius: 2px;
      font-size: 12px;
      color: #409eff;
      padding: 5px 8px;
      margin-right: 8px;
    }
  }
  .tyleTables {
    position: relative;
    .tabBtn {
      z-index: 9;
      position: absolute;
      right: 0px;
    }
  }
}
</style>
<style >
.txForm .Y-myFrom .el-form-item {
  margin-bottom: 0;
}
.txForm .Y-myFrom .el-form-item__label {
  color: #909399;
}
</style>
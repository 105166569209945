<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' title="文档类型" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="500px" :before-close="closeMypup">
			<div >
				<TForm  ref="html2From" lableRow labelPosition='left'  :autoCheck='true' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'></TForm>
				<div class="btmBtn" >
	    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
	    		<el-button type="primary" @click='validateFroms' :disabled='isWait'> 保存</el-button>
        </div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			useType: {//使用类型， 选择 select 用户 或者手输 input 信息
				type: String,
				default: 'input',
			},
			typeArrs:{
				type:Array,
				default:[]
			},
			enterpriseId: { //useType==select 时候 需要传企业ID enterpriseId
				type: String,
				default: '',
			},
		},
		components: {TForm},
		data() {
			return {
				isWait:false,
				htmlDatas:{},
	  			formlist:[
	  				{name: '文档名称',keys: 'documentName',value: '',type: 'txRow',flex:24,},
	  				{name: '选择类型',keys: 'documentType',value: '',type: 'select',flex:24,isMust:true,options:this.typeArrs},
	  			],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					if(this.$refs.html2From) this.$refs.html2From.resetFields();
					this.getDatasById();
				}
			},
			
		},
		created() {
			
		},
		mounted() {
			this.resetTFrom();
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			resetTFrom(){
				if(this.$refs.html2From) this.$refs.html2From.resetFields();
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			closeMypup(){
				this.$emit('close')
			},
			async getDatasById(){
				this.resetTFrom();	
				if(this.dataId){
					let res = await this.ApiHttp('/organization/enterprise/document/selectById?enterpriseDocumentId='+this.dataId);
					if(res){
						this.htmlDatas={...this.htmlDatas,...res}
						this.common.insertOptions(this.formlist,'documentType',this.typeArrs);//添加选项数据到所属项目
					}
				}
			},
			validateFroms(){
				let allow=this.$refs.html2From.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
				})
			},
			async getFromDatas(){
				let params={
					enterpriseDocumentId:+this.dataId,
					documentType:this.htmlDatas.documentType,
				}
				this.isWait=true;
				setTimeout(()=> {
					this.isWait=false
				},1500);
				let res = await this.ApiHttp('/organization/enterprise/document/update',params,'post');
				if(res){
					this.$refs.html2From.resetFields();
					this.$emit('close','init')
				}
				
				
			},
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>